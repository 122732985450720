import React from "react"
import { useKeycloak } from "@react-keycloak/web"
import { useIdleTimer } from "react-idle-timer"

import Main from "../components/Main"
import { navigate } from "gatsby"

const Home = () => {
    const [beforeRedirect, setBeforeRedirect] = React.useState(
        sessionStorage.getItem("fullReload") !== null ? false : true
    )
    React.useEffect(() => {
        if (sessionStorage.getItem("fullReload") !== null) {
            navigate("/requests", {
                state: { tabIndex: 2 },
            })
            sessionStorage.removeItem("fullReload")
        }
    }, [])

    return <>{beforeRedirect && <Main />}</>
}

export default Home
